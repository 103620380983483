import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'interpolate' })
export class InterpolatePipe implements PipeTransform {

    transform(input: string, ...replacements: string[]): string {
        for (let i = 0; input && i < replacements.length; i++) {
            input = input.replace(`\{${i}\}`, replacements[i]);
        }
        return input;
    }
}

interface String {
    format(first: any, ...rest: any[]): string;
    toCamelCase(): string;
    isNullOrEmpty(): boolean;
}

// An extension method to add formating capability to string type
String.prototype.format = function (first: string, ...rest: string[]) {
    let content = this;

    content = content.replace('{0}', first ? first.toString() : '');
    for (let i = 0; i < rest.length; i++) {
        const replacement = '{' + (i + 1) + '}';
        content = content.replace(replacement, rest[i] ? rest[i].toString() : '');
    }
    return content;
};

String.prototype.toCamelCase = function () {
    const str = this;
    return str.replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
        .replace(/\s/g, '')
        .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
};

String.prototype.isNullOrEmpty = function (): boolean {
    return !this;
};

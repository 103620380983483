import { Component, ViewEncapsulation, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AnimationEvent } from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { InplaceToastAnimations } from './toast.animation';
import { Toast } from './toast.model';
import { ToastOptions } from './toast.options';

@Component({
    selector: 'inp-toast',
    template: `
    <div id="inp-toast-container" [style.position]="position" class="{{positionClass}}">
      <div *ngFor="let toast of toasts" [@inOut]="animate" (@inOut.done)="onAnimationEnd($event)"
        class="inp-toast inp-toast-{{toast.type}}" (click)="clicked(toast)">
        <div class="inp-toast-close-button" *ngIf="toast.config.showCloseButton" (click)="remove(toast)">&times;
        </div>
        <div *ngIf="toast.title" class="{{toast.config.titleClass || titleClass}}">{{toast.title}}</div>
        <div [ngSwitch]="toast.config.enableHTML">
          <span *ngSwitchCase="true" class="{{toast.config.messageClass || messageClass}}"
            [innerHTML]="sanitizer.bypassSecurityTrustHtml(toast.message)"></span>
          <span *ngSwitchDefault class="{{toast.config.messageClass || messageClass}}">{{toast.message}}</span>
        </div>
      </div>
    </div>
    `,
    animations: [InplaceToastAnimations.translateToast],
    encapsulation: ViewEncapsulation.None,
})
export class ToastComponent implements OnDestroy {
    position = 'fixed';
    messageClass: string;
    titleClass: string;
    positionClass: string;
    maxShown: number;
    newestOnTop: boolean;
    animate: string;
    toasts: Toast[] = [];

    private _fresh = true;
    private onToastClicked: (toast: Toast) => void;

    private _onEnter: Subject<any> = new Subject();
    private _onExit: Subject<any> = new Subject();

    constructor(
        public sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
        private _zone: NgZone,
        options: ToastOptions
    ) {
        Object.assign(this, options);
    }

    onEnter(): Observable<void> {
        return this._onEnter.asObservable();
    }

    onExit(): Observable<void> {
        return this._onExit.asObservable();
    }

    add(toast: Toast) {
        if (this.positionClass.indexOf('top') > 0) {
            if (this.newestOnTop) {
                this.toasts.unshift(toast);
            } else {
                this.toasts.push(toast);
            }

            if (this.toasts.length > this.maxShown) {
                const diff = this.toasts.length - this.maxShown;

                if (this.newestOnTop) {
                    this.toasts.splice(this.maxShown);
                } else {
                    this.toasts.splice(0, diff);
                }
            }
        } else {
            this.toasts.unshift(toast);
            if (this.toasts.length > this.maxShown) {
                this.toasts.splice(this.maxShown);
            }
        }

        if (this.animate === null && this._fresh) {
            this._fresh = false;
            this._onEnter.next();
            this._onEnter.complete();
        }

        this.cdr.detectChanges();
    }

    remove(toast: Toast) {
        if (toast.timeoutId) {
            clearTimeout(toast.timeoutId);
            toast.timeoutId = null;
        }

        this.toasts = this.toasts.filter(t => {
            return t.id !== toast.id;
        });
    }

    removeAll() {
        this.toasts = [];
    }

    clicked(toast: Toast) {
        if (this.onToastClicked) {
            this.onToastClicked(toast);
        }
    }

    any(): boolean {
        return this.toasts.length > 0;
    }

    find(id: number): Toast | void {
        for (const toast of this.toasts) {
            if (toast.id === id) {
                return toast;
            }
        }
        return null;
    }

    onAnimationEnd(event: AnimationEvent) {
        if (event.toState === 'void' && !this.any()) {
            this._ngExit();
        } else if (this._fresh && event.fromState === 'void') {
            // notify when first animation is done
            this._fresh = false;
            this._zone.run(() => {
                this._onEnter.next();
                this._onEnter.complete();
            });
        }
    }

    private _ngExit() {
        this._zone.onMicrotaskEmpty.pipe(first()).subscribe(() => {
            this._onExit.next();
            this._onExit.complete();
        });
    }

    ngOnDestroy() {
        this._ngExit();
    }
}

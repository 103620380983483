import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {

    store<T>(key: string, object: T) {
        sessionStorage.setItem(key, JSON.stringify(object));
    }

    retrieve<T>(key: string): T {
        const value = sessionStorage.getItem(key);
        if (value == null) {
            return null;
        }

        return JSON.parse(value) as T;
    }

    remove(key: string) {
        return sessionStorage.removeItem(key);
    }
}

export class ListItem {
    listItemDisplayText: string;
    listItemId: string;
    listItemValue?: string;
    type?: any;

    constructor(listItemId: string = '', listItemDisplayText = '') {
        this.listItemId = listItemId;
        this.listItemDisplayText = listItemDisplayText;
        this.listItemValue = null;
    }
}

export class CheckListItem extends ListItem {
    selected: boolean;
}

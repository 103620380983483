import { Injectable } from '@angular/core';

@Injectable()
export class ToastOptions {
    positionClass = 'inp-toast-top-right';
    maxShown = 5;
    newestOnTop = false;
    animate = 'fade';

    // override-able properties
    toastLife = 5000;
    enableHTML = false;
    dismiss = 'auto'; // 'auto' | 'click' | 'controlled'
    messageClass = 'inp-toast-message';
    titleClass = 'inp-toast-title';
    showCloseButton = false;

    constructor() { }
}

import { NgModule } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  entryComponents: [],
  providers: [
    SessionStorageService,
    LocalStorageService
  ]
})
export class InPlaceStorageModule {
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface ICoreConfig {
    auth: {
        authorizationUrl: string;
        redirectPath: string;
        clientId: string;
        scope: string;
    };
    comms: {
        apiBaseUrl: string;
    };
}

@Injectable({ providedIn: 'root' })
export class ConfigProvider<T extends ICoreConfig> {
    settings: T = <T>{};

    constructor(private http: HttpClient) {
    }

    /**
    * Loads the config.json file
    */
    load(): Promise<void> {
        const jsonFile = `assets/config.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: T) => {
                this.settings = response;
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigProvider, ICoreConfig } from '@inplace/core';
import { LocalStorageService, SessionStorageService } from '@inplace/storage';
import { Token, AuthenicationResponse, IdToken } from './identity-server.model';
import { Observable, from } from 'rxjs';

export enum RoleType {
    Student = 1,
    Staff = 2,
    Employer = 3,
    Academic = 4,
    StaffSupervisor = 5,
    EmployerSupervisor = 6
}

class UserSettings {
    roleType: RoleType;
    token: string;
    id_token: string;
}

export class CallbackParams {
    authenticated: boolean;
    returnRoute: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private key = 'user-settings';
    private settings: UserSettings;
    private tokenData: Token;

    constructor(private injector: Injector,
        private configProvider: ConfigProvider<ICoreConfig>,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService) {
        this.retrieveSettings();
    }

    // This is to prevent the cyclic dependency.
    // http -> router, router -> http
    get router(): Router {
        return this.injector.get(Router);
    }

    authorize(returnRoute: string = '') {
        this.resetAuthorizationData();
        if (returnRoute != null && returnRoute !== '') {
            this.localStorageService.store('returnRoute', returnRoute);
        }
        const authorizationUrl = `${this.configProvider.settings.auth.authorizationUrl}/connect/authorize`;
        const client_id = this.configProvider.settings.auth.clientId;
        const response_type = 'code id_token token';
        const scope = this.configProvider.settings.auth.scope;
        const nonce = `N${Math.random()}${Date.now()}`;
        const state = `${Date.now()}${Math.random()}`;
        const url = `${authorizationUrl}?` +
            `response_type=${encodeURI(response_type)}&` +
            `client_id=${encodeURI(client_id)}&` +
            `redirect_uri=${encodeURI(this.redirectUrl)}&` +
            `scope=${encodeURI(scope)}&` +
            `nonce=${encodeURI(nonce)}&` +
            `state=${encodeURI(state)}`;

        this.sessionStorageService.store('authStateControl', state);
        this.sessionStorageService.store('authNonce', nonce);

        window.location.href = url;
    }

    authorizedCallback(): Observable<CallbackParams> {
        const promise = new Promise<CallbackParams>((resolve, reject) => {
            this.resetAuthorizationData();
            const hash = window.location.hash.substr(1);

            const result: AuthenicationResponse = hash.split('&').reduce(function (res: any, item: string) {
                const parts = item.split('=');
                res[parts[0]] = parts[1];
                return res;
            }, {});

            let token = '';
            let id_token = '';
            let authResponseIsValid = false;

            if (!result.error) {
                if (result.state !== this.sessionStorageService.retrieve('authStateControl')) {
                    console.log('Authorized Callback incorrect state');
                } else {
                    token = result.access_token;
                    id_token = result.id_token;
                    const dataIdToken: IdToken = this.decodeToken(id_token);

                    if (dataIdToken.nonce !== this.sessionStorageService.retrieve('authNonce')) {
                        console.log('Authorized Callback incorrect nonce');
                    } else {
                        authResponseIsValid = true;
                        this.sessionStorageService.remove('authStateControl');
                        this.sessionStorageService.remove('authNonce');
                    }
                }
            }

            if (authResponseIsValid) {
                this.setAuthorizationData(token, id_token);
                this.setUserType();
            } else {
                this.resetAuthorizationData();
            }

            const returnRoute = this.localStorageService.retrieve<string>('returnRoute');
            this.localStorageService.remove('returnRoute');

            resolve({
                authenticated: authResponseIsValid,
                returnRoute: returnRoute
            });
        });

        return from(promise);
    }

    setUserType(preferedRole: string = '') {
        const tokenData = this.getTokenData();
        if (this.isAuthorized && tokenData) {
            this.resetUserType();
            if (preferedRole === 'staff' && tokenData.stfid) {
                this.settings.roleType = RoleType.Staff;
            } else if (preferedRole === 'employer' && tokenData.empid) {
                this.settings.roleType = RoleType.Employer;
            } else if (preferedRole === 'academic' && tokenData.stfid && (tokenData.stfrole || tokenData.acdrole)) {
                this.settings.roleType = RoleType.Academic;
            } else if (preferedRole === 'supervisor') {
                if (tokenData.spvrole) {
                    this.settings.roleType = RoleType.StaffSupervisor;
                } else if (tokenData.empspvrole) {
                    this.settings.roleType = RoleType.EmployerSupervisor;
                }
            } else {
                if (tokenData.empid && !(tokenData.stfid && (tokenData.stfrole || tokenData.acdrole || tokenData.spvrole))) {
                    if (tokenData.emprole) {
                        this.settings.roleType = RoleType.Employer;
                    } else if (tokenData.empspvrole) {
                        this.settings.roleType = RoleType.EmployerSupervisor;
                    }
                } else if (tokenData.stfid) {
                    if (tokenData.stfrole) {
                        this.settings.roleType = RoleType.Staff;
                    } else if (tokenData.acdrole) {
                        this.settings.roleType = RoleType.Academic;
                    } else if (tokenData.spvrole) {
                        this.settings.roleType = RoleType.StaffSupervisor;
                    }
                } else if (tokenData.stuid) {
                    if (tokenData.sturole) {
                        this.settings.roleType = RoleType.Student;
                    }
                }
            }

            this.storeSettings();
        }
    }

    logoff(callback: Function = null) {
        const authorizationUrl = `${this.configProvider.settings.auth.authorizationUrl}/connect/endsession`;
        const id_token = this.settings.id_token;
        const state = `${Date.now()}${Math.random()}`;
        const url = `${authorizationUrl}?id_token_hint=${id_token}&` +
            `post_logout_redirect_uri=${encodeURI(this.redirectUrl)}&state=${encodeURI(state)}`;
        this.resetAuthorizationData();
        if (callback && callback) {
            callback();
        }
        window.location.href = url;
    }

    get token(): string {
        return this.settings.token;
    }

    get currentRole(): string {
        if (this.isAuthorized) {
            if (this.isStaff) {
                return RoleType.Staff.toString();
            }
            if (this.isAcademic) {
                return RoleType.Academic.toString();
            }
            if (this.isEmployer) {
                return RoleType.Employer.toString();
            }
            if (this.isStaffSupervisor) {
                return RoleType.StaffSupervisor.toString();
            }
            if (this.isEmployerSupervisor) {
                return RoleType.EmployerSupervisor.toString();
            }
        }
    }

    get roles(): RoleType[] {
        const roles: RoleType[] = [];
        if (this.getTokenData()) {
            if (this.getTokenData().acdrole) {
                roles.push(RoleType.Academic);
            }
            if (this.getTokenData().stfrole) {
                roles.push(RoleType.Staff);
            }
            if (this.getTokenData().emprole) {
                roles.push(RoleType.Employer);
            }
            if (this.getTokenData().spvrole) {
                roles.push(RoleType.StaffSupervisor);
            }
            if (this.getTokenData().empspvrole) {
                roles.push(RoleType.EmployerSupervisor);
            }
            if (this.getTokenData().sturole) {
                roles.push(RoleType.Student);
            }
        }
        return roles;
    }

    get username(): string {
        if (this.getTokenData()) {
            return this.getTokenData().name;
        }
        return null;
    }

    get staffId(): number {
        if (this.getTokenData()) {
            return this.getTokenData().stfid;
        }
        return null;
    }

    get agencyPersonnelId(): number {
        if (this.getTokenData()) {
            return this.getTokenData().empid;
        }
        return null;
    }

    get agencyId(): number {
        if (this.getTokenData()) {
            return this.getTokenData().agencyid;
        }
        return null;
    }

    get isAuthorized(): boolean {
        if (!this.settings.token) {
            return false;
        }
        if (!this.settings.id_token) {
            return false;
        }
        return true;
    }

    get isStaff(): boolean {
        return this.settings.roleType === RoleType.Staff;
    }

    get isAcademic(): boolean {
        return this.settings.roleType === RoleType.Academic;
    }

    get isEmployer(): boolean {
        return this.settings.roleType === RoleType.Employer;
    }

    get isStaffSupervisor(): boolean {
        return this.settings.roleType === RoleType.StaffSupervisor;
    }

    get isEmployerSupervisor(): boolean {
        return this.settings.roleType === RoleType.EmployerSupervisor;
    }

    get isStudent(): boolean {
        return this.settings.roleType === RoleType.Student;
    }

    private setAuthorizationData(token: any, id_token: any) {
        this.settings.token = token;
        this.settings.id_token = id_token;
        this.storeSettings();
        this.tokenData = this.decodeToken(this.settings.token);
    }

    private resetAuthorizationData() {
        this.settings = Object.assign({});
        this.storeSettings();
        this.resetUserType();
    }

    private base64Decode(str: string) {
        let output = str.replace('-', '+').replace('_', '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw new Error('Illegal base64url string!');
        }
        return window.atob(output);
    }

    private decodeToken(token: string): any {
        let data = {};
        if (token) {
            const encoded = token.split('.')[1];
            data = JSON.parse(this.base64Decode(encoded));
        }
        return data;
    }

    private storeSettings() {
        this.localStorageService.store(this.key, this.settings);
    }

    private retrieveSettings() {
        this.settings = this.localStorageService.retrieve<UserSettings>(this.key);
        if (!this.settings) {
            this.settings = Object.assign({});
            this.storeSettings();
        }
    }

    private resetUserType() {
        this.settings.roleType = null;
        this.storeSettings();
    }

    private getTokenData() {
        if (!this.tokenData) {
            this.tokenData = this.decodeToken(this.settings.token);
        }
        return this.tokenData;
    }

    private get redirectUrl(): string {
        if (this.configProvider.settings.auth.redirectPath.startsWith('/')) {
            return `${location.origin}${this.configProvider.settings.auth.redirectPath}`;
        }
        return `${location.origin}/${this.configProvider.settings.auth.redirectPath}`;
    }
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';

import './extensions';
import { InPlaceToastModule, ToastOptions } from '@inplace/toast';
import { ConfigProvider } from './services/config-provider.service';
import { NotificationService } from './notification/notification.service';
import { ConfirmComponent } from './notification/confirm/confirm.component';
import { InterpolatePipe } from './pipes/interpolate.pipe';
import { OrdinalPipe } from './pipes/ordinal/ordinal.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TitleCasePipe } from './pipes/title-case/title-case.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { FileDownloadService } from './services/file-download.service';
import { MessagingService } from './services/messaging.servise';
import { WindowService } from './services/window.service';

export interface CoreConfig {
    notification?: ToastOptions;
}

@NgModule({
    imports: [
        BrowserModule,
        MatDialogModule,
        InPlaceToastModule.forRoot()
    ],
    declarations: [
        InterpolatePipe,
        OrdinalPipe,
        SafeHtmlPipe,
        TitleCasePipe,
        SentenceCasePipe,
        ConfirmComponent
    ],
    exports: [
        InterpolatePipe,
        OrdinalPipe,
        SafeHtmlPipe,
        TitleCasePipe,
        SentenceCasePipe
    ],
    entryComponents: [
        ConfirmComponent
    ]
})
export class InPlaceCoreModule {
    public static forRoot(config?: CoreConfig): ModuleWithProviders {
        return {
            ngModule: InPlaceCoreModule,
            providers: [
                ConfigProvider,
                NotificationService,
                FileDownloadService,
                MessagingService,
                WindowService,
                {
                    provide: ToastOptions, useValue: config != null && config.notification != null
                        ? config.notification : {}
                }
            ]
        };
    }
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from './toast.service';
import { ToastOptions } from './toast.options';
import { ToastComponent } from './toast.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ToastComponent],
  exports: [ToastComponent],
  entryComponents: [ToastComponent]
})
export class InPlaceToastModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: InPlaceToastModule,
      providers: [ToastService, ToastOptions]
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sentenceCase' })
export class SentenceCasePipe implements PipeTransform {
    public transform(input: string): string {
        if (!input) {
            return '';
        }
        const sentences = input.match(/[^\.!\?]+[\.!\?]+/g);
        let result = '';
        sentences.forEach(sentence => {
            let i = 0;
            while (i < sentence.length && sentence[i] === ' ') {
                i++;
            }
            result += sentence.substr(0, i) + sentence[i].toUpperCase() + sentence.substr(i + 1).toLowerCase();
        });
        return result;
    }
}

import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

    store<T>(key: string, object: T) {
        localStorage.setItem(key, JSON.stringify(object));
    }

    retrieve<T>(key: string): T {
        const value = localStorage.getItem(key);
        if (value == null) {
            return null;
        }

        return JSON.parse(value) as T;
    }

    remove(key: string) {
        return localStorage.removeItem(key);
    }
}

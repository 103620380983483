interface Number {
    padLeft(width: number, char?: string): string;
}

Number.prototype.padLeft = function padLeft(width: number, char: string = '0') {
    let value = this;
    char = char || '0';
    value = value + '';
    return value.length >= width ? value : new Array(width - value.length + 1).join(char) + value;
};

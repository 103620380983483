import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { InPlaceStorageModule } from '@inplace/storage';
import { AuthService } from './auth.service';
import { HttpClientService } from './http-client.service';
import { AuthGuard } from './guards/auth.guard';

@NgModule({
  imports: [
    HttpClientModule,
    InPlaceStorageModule
  ],
  declarations: [],
  exports: [],
  providers: []
})
export class InPlaceHttpModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: InPlaceHttpModule,
      providers: [
        HttpClientService,
        AuthService,
        AuthGuard
      ]
    };
  }
}

import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ToastService } from '@inplace/toast';
import { Observable } from 'rxjs';
import { ConfirmComponent } from './confirm/confirm.component';

export class ConfirmationDialogSettings {
    title?: string;
    cancelButton?: string;
    confirmButton?: string;
    width?: string;
    maxWidth?: string;
}

@Injectable({ providedIn: 'root' })
export class NotificationService {

    constructor(private toastService: ToastService,
        private dialog: MatDialog) {
    }

    setRootViewContainerRef(viewRef: ViewContainerRef) {
        this.toastService.setRootViewContainerRef(viewRef);
    }

    success(message: string, title: string = null, options: any = null) {
        if (!title) {
            title = 'Success';
        }
        this.toastService.success(message, title, options);
    }

    error(message: string, title: string = null, options: any = null) {
        if (!title) {
            title = 'Error';
        }
        this.toastService.error(message, title, options);
    }

    errors(messages: string[], title: string = null, options: any = null) {
        if (!title) {
            title = 'Error';
        }
        if (messages.length === 1) {
            this.toastService.error(messages[0], title, options);
        } else {
            let message = '<ul class="multi-message">';
            for (let i = 0; i < messages.length; i++) {
                message += `<li>${messages[i]}</li>`;
            }
            message += '</ul>';
            if (options === null) {
                options = { enableHTML: true };
            } else {
                options.enableHTML = true;
            }
            this.toastService.error(message, title, options);
        }
    }

    warning(message: string, title: string = null, options: any = null) {
        if (!title) {
            title = 'Warning';
        }
        this.toastService.warning(message, title, options);
    }

    warnings(messages: string[], title: string = null, options: any = null) {
        if (!title) {
            title = 'Warning';
        }
        if (messages.length === 1) {
            this.toastService.warning(messages[0], title, options);
        } else {
            let message = '<ul class="multi-message">';
            for (let i = 0; i < messages.length; i++) {
                message += `<li>${messages[i]}</li>`;
            }
            message += '</ul>';
            if (options === null) {
                options = { enableHTML: true };
            } else {
                options.enableHTML = true;
            }
            this.toastService.warning(message, title, options);
        }
    }

    info(message: string, title: string = null, options: any = null) {
        if (!title) {
            title = 'Info';
        }
        this.toastService.info(message, title, options);
    }

    confirm(message: string, settings?: ConfirmationDialogSettings): Observable<any> {
        const config = new MatDialogConfig();
        config.disableClose = true;
        if (settings) {
            config.width = settings.width;
            config.maxWidth = settings.maxWidth ? settings.maxWidth : '400px';
        }

        const dialogRef = this.dialog.open(ConfirmComponent, config);
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.title = 'Confirm';
        dialogRef.componentInstance.cancelButton = 'No';
        dialogRef.componentInstance.confirmButton = 'Yes';
        if (settings) {
            dialogRef.componentInstance.title = settings.title ? settings.title : 'Confirm';
            dialogRef.componentInstance.cancelButton = settings.cancelButton ? settings.cancelButton : 'No';
            dialogRef.componentInstance.confirmButton = settings.confirmButton ? settings.confirmButton : 'Yes';
        }

        return dialogRef.afterClosed();
    }
}

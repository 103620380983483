interface Date {
    toDateTimeString(includeTime?: boolean): string;
}

Date.prototype.toDateTimeString = function (includeTime: boolean = false) {
    const date = this;
    const day = date.getDate().padLeft(2);
    const month = (date.getMonth() + 1).padLeft(2);
    const year = date.getFullYear();
    let hour = '00';
    let min = '00';
    let sec = '00';
    if (includeTime) {
        hour = date.getHours().padLeft(2);
        min = date.getMinutes().padLeft(2);
        sec = date.getSeconds().padLeft(2);
    }

    return '{0}-{1}-{2}T{3}:{4}:{5}'.format(year, month, day, hour, min, sec);
};

export class ListSortDescriptor {
    field: string;
    dir?: 'asc' | 'desc';
}

export class ListState {
    pageSizes: number[] | boolean = [10, 20, 40, 50, 100];
    pageSize = 40;
    skip = 0;
    sort?: ListSortDescriptor;

    get page() {
        return Math.floor(this.skip / this.pageSize) + 1;
    }

    get orderDirection() {
        return this.sort && this.sort ? this.sort.dir : 'asc';
    }

    get orderField() {
        return this.sort && this.sort ? this.sort.field : null;
    }
}

export class List {
    state: ListState;

    constructor() {
        this.state = new ListState();
    }
}

export class CommandResult {
    public errors: string[];
    public isSuccess: boolean;
    public warnings: string[];
    public hasWarnings: boolean;
}

export class CommandResultValue<T> extends CommandResult {
    public value: T;
}

export class PagedResult<T> {
    total: number;
    items: T[];
}

import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export class ConfirmResult {
    public cancel: boolean;
}

@Component({
    template: `<div class="md-dialog-container inp-confirm-dialog">
    <div mat-dialog-title>
        <div>
            {{title}}
        </div>
        <div>
            <i class="glyphicon glyphicon glyphicon-remove" (click)="cancel();" title="Close"></i>
        </div>
    </div>

    <div mat-dialog-content>
        {{message}}
    </div>

    <div mat-dialog-actions>
        <button class="btn btn-link" (click)="cancel()">
            <i class="glyphicon glyphicon glyphicon-remove"></i>
            <span>
                {{cancelButton}}
            </span>
        </button>
        <button class="btn btn-primary" (click)="confirm()">
            <span>
                {{confirmButton}}
            </span>
        </button>
    </div>
</div>`,
    encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent {
    title: string;
    message: string;
    cancelButton: string;
    confirmButton: string;

    constructor(private dialogRef: MatDialogRef<ConfirmComponent>) { }

    cancel() {
        this.dialogRef.close(<ConfirmResult>{ cancel: true });
    }

    confirm() {
        this.dialogRef.close(<ConfirmResult>{ cancel: false });
    }
}

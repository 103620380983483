import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface Message {
    type: string;
    payload: any;
}

export type MessagingCallback = (payload: any) => void;

@Injectable({ providedIn: 'root' })
export class MessagingService {
    private handler = new Subject<Message>();

    broadcast(type: string, payload: any = null) {
        payload = payload != null ? payload : {};
        this.handler.next({ type, payload });
    }

    subscribe(type: string, callback: MessagingCallback): Subscription {
        return this.handler
            .pipe(filter(m => m.type === type))
            .pipe(map(m => m.payload))
            .subscribe(callback);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class HttpClientService {
    constructor(private http: HttpClient,
        private authService: AuthService) {
    }

    private setHeaders(contentType: string = 'application/json'): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        if (contentType != null) {
            headers = headers.append('Content-Type', contentType);
        }

        headers = headers.append('Accept', 'application/json');

        const token = this.authService.token;
        if (token) {
            const tokenValue = 'Bearer ' + token;
            headers = headers.append('Authorization', tokenValue);
        }

        const currentRole = this.authService.currentRole;
        if (currentRole) {
            headers = headers.append('RoleTypeOverride', currentRole);
        }

        //  Hack for disabling IE ajax request caching
        headers = headers.append('If-Modified-Since', 'Sat, 1 Jan 2000 00:00:00 GMT');
        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Pragma', 'no-cache');
        return headers;
    }

    get<T>(url: string, params: HttpParams = null): Observable<T> {
        const headers = this.setHeaders();
        return this.http.get<T>(url, { headers: headers, params: params });
    }

    post<T>(url, data, contentType: string = 'application/json', params: HttpParams = null): Observable<T> {
        const headers = this.setHeaders(contentType);
        return this.http.post<T>(url, data, { headers: headers, params: params });
    }

    put<T>(url, data, contentType: string = 'application/json'): Observable<T> {
        const headers = this.setHeaders(contentType);
        return this.http.put<T>(url, data, { headers: headers });
    }

    delete<T>(url: string, params: HttpParams = null): Observable<T> {
        const headers = this.setHeaders();
        return this.http.delete<T>(url, { headers: headers, params: params });
    }
}
